import jQuery from "jquery";

jQuery(() => {
    jQuery('.drag-drop-file-upload .upload-drop').on('dragenter dragover', function(e) {
        jQuery(this).addClass('drag-over');

        e.preventDefault();
        e.stopPropagation();
    }).on('dragend dragleave', function(e) {
        jQuery(this).removeClass('drag-over');

        e.preventDefault();
        e.stopPropagation();
    }).on('drop', function(e) {
        jQuery(this).removeClass('drag-over');

        jQuery('input[type=file]', this).prop("files", e.originalEvent.dataTransfer.files);

        for(let i = 0; i < e.originalEvent.dataTransfer.files.length; i++) {
            let file = e.originalEvent.dataTransfer.files[i];

            jQuery(displayFileTemplate(file)).appendTo(this);
        }

        jQuery('.upload-placeholder', this).remove();
        jQuery('button[disabled]', jQuery(this).parents('form', 0)).prop('disabled', false);

        e.preventDefault();
        e.stopPropagation();
    }).on('click', function(e) {
        jQuery('input[type=file]', this).click();

        e.preventDefault();
        e.stopPropagation();
    });

    jQuery('.drag-drop-file-upload .upload-drop input[type=file]').on('change', function(e) {
        console.log(jQuery(this).prop('files'));
        for(let i = 0; i < jQuery(this).prop('files').length; i++) {
            let file = jQuery(this).prop('files')[i];
            let parent = jQuery(this).parents('.upload-drop', 0);

            jQuery(displayFileTemplate(file)).appendTo(parent);
            jQuery('.upload-placeholder', parent).remove();
            jQuery('button[disabled]', jQuery(parent).parents('form', 0)).prop('disabled', false);
        }
    }).on('click', function(e) {
        e.stopPropagation();
    })
});

function displayFileTemplate(file) {
    let htmlTemplate = "<div class='uploaded-file'>" +
        "<div class='uploaded-file-icon'><i class='bx bxs-file-blank'></i></div>" +
        "<div class='uploaded-file-info'>" +
            "<span class='file-name'>" + file.name + "</span>" +
            "<span class='file-size'>" + Math.round(file.size / 1000) + " kB</span>"
        "</div>";

    return htmlTemplate;
}
