// Import jQuery from library
import jQuery from "jquery";
// import * as $ from "jquery";
window.jQuery = window.$ = jQuery
let $ = jQuery;

// Import Apex charts
import ApexCharts from "apexcharts-clevision";
import "../assets/vendor/libs/apex-charts/apex-charts.scss"

// Import ChartJS
import "../assets/vendor/libs/chartjs/chartjs"
import "../assets/vendor/libs/chartjs-plugin-style/chartjs-plugin-style"

// Import Select 2 from library
import("select2").then(m => m.default());
// no point for naming
import select2 from 'select2'
//import 'select2/dist/css/select2.css'
select2();

// Import utils
import './utils/hashcode';
import './utils/clone-element';

// Import Frest utils
import {Helpers} from "../assets/vendor/js/helpers";
import * as config from '../assets/js/config';
window.Helpers = Helpers;

import '../assets/js/ui-menu';
import '../assets/js/main';

// Import Twitter Bootstrap
/*import * as bootstrap from "bootstrap";
import { Tooltip, Toast, Popover, Dropdown } from 'bootstrap';*/

// Import typeahead
import "typeahead.js/dist/typeahead.jquery"
// import '../assets/vendor/libs/typeahead-js/typeahead.scss'

// import "../assets/vendor/libs/jquery-repeater/jquery-repeater.js"

// Import EasyUI
import "./filter-table"
import './utils/jquery.multi-select'

jQuery(() => {
    jQuery(document).ready(() => {
        if(jQuery('#multipleSelect').length > 0) {
            jQuery('#multipleSelect').multiSelect({
                'keepOrder'         : true
            });
        }
    })

    jQuery('.select2').select2();
    jQuery('.select2-free-input').select2({
        multiple: true,
        tags: true,
        tokenSeparators: [',', ' '],
        createTag: function (params) {
            var term = jQuery.trim(params.term);

            if (term === '') {
                return null;
            }

            return {
                id: term,
                text: term,
                newTag: true // add additional parameters
            }
        }
    });

    jQuery('[data-apex-chart]').each(function() {
        let config = window.graphConfig[jQuery(this).attr('id')];
        if (config) {
            let c = new ApexCharts(jQuery(this)[0], config);
            c.render();
        } else {
            alert(jQuery(this).attr('id') + ' graph config not found');
        }
    });

    jQuery('select[data-type=actions]').on('change', function(e) {
        jQuery('#temp-form').remove();

        e.preventDefault()
        e.stopPropagation()

        if (jQuery(this).val() != '') {
            let html = "<form action='" + jQuery(this).val() + "' method='post' id='temp-form'>";
            html += '<input type="hidden" name="_token" value="' + jQuery(this).data('token') + '">';
            jQuery('[data-type="actions-checkbox"]:checked').each(function () {
                html += "<input type='hidden' name='id[]' value='" + jQuery(this).data('id') + "' />";
            })
            html += "</form>";
            jQuery(html).appendTo('body');
            jQuery('#temp-form').submit();
        }
    })
});

export default window;
