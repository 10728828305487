<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="user-profile-header-banner">
                        <!--<img src="/images/profile/covers/cover5.svg" alt="Banner image" class="rounded-top" id="profileBannerImageTrianglify">-->
                    </div>
                    <div class="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                        <div class="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                            <img v-bind:src="user.avatar" alt="Avatar" class="d-block h-auto ms-0 ms-sm-4 rounded-3 user-profile-img">
                        </div>
                        <div class="flex-grow-1 mt-3 mt-sm-5">
                            <div class="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                <div class="user-profile-info">
                                    <h4 class="mb-0">{{user.name}}</h4>

                                    <ul class="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                                        <li class="list-inline-item fw-semibold text-muted">
                                            <i class="las la-user"></i>
                                            {{ user.role }}
                                        </li>
                                    </ul>
                                </div>
                                <router-link :to="{name: 'users.edit', params: { id: user.id}}" v-if="can('Users::Edit')">
                                    <i class="las la-pen"></i> Bewerken
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <div class="card">
                    <div class="card-body">
                        <small class="text-muted text-uppercase">Over</small>
                        <ul class="list-unstyled mb-4 mt-3">
                            <li class="d-flex align-items-center mb-3">
                                <i class="las la-user"></i>
                                <span class="fw-semibold mx-2">Naam:</span>
                                <span>{{ user.name }}</span>
                            </li>
                            <li class="d-flex align-items-center mb-3">
                                <i class="las la-at"></i>
                                <span class="fw-semibold mx-2">E-mail:</span>
                                <span>{{ user.email }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-8">
                <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Projecten</h4>
                    </div>
                    <div class="card-body">
                        <table class="table datatable">
                            <thead>
                            <tr>
                                <th>Klant</th>
                                <th>Website</th>
                                <th>Type</th>
                                <th>Maandbudget</th>
                                <th>Budget restant</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="subscription in user.subscriptions" :key="subscription">
                                <td>{{subscription.customer.name}}</td>
                                <td>{{subscription.website.name}}</td>
                                <td>{{subscription.type}}</td>
                                <td>{{subscription.budget}}</td>
                                <td v-bind:class="subscription.budget_class" style="--bs-bg-opacity: .25;">{{subscription.budget_available}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: []
        }
    },
    mounted() {
        fetch('/api/users/' + this.$route.params.id)
            .then(resp => resp.json())
            .then(json => this.user = json)
    }
}
</script>
