<template>
    <div>
        <div class="mb-3">
            <label for="roles_id" class="form-label">Gebruikersrol</label>
            <select name="roles_id" id="roles_id" class="select2 form-control"
                v-model="user.role_id">
                <option v-for="role in roles" :key="role"
                    v-bind:value="role.id"
                v-bind:selected="{selected: (role.id == user.role_id)}">{{role.name}}</option>
            </select>

            <span class="text-muted">
            Een gebruiker kan meerdere rollen hebben. Op deze manier kan je de permissies van een gebruiker uitbreiden.
            Gebruikers die Super Admin zijn hebben altijd toegang tot alles.
                <span v-if="can('Users::Create')">
                    Je kan permissies toekennen aan een rol via het overzicht <router-link to="users/roles">gebruikersrollen</router-link>.
                </span>
            </span>
        </div>

        <div class="mb-3">
            <label for="name" class="form-label" id="nameLabel">Naam</label>
            <input type="text" name="name" id="name" class="form-control" aria-labelledby="nameLabel"
                   v-model="user.name">
        </div>

        <div class="mb-3">
            <label for="email" class="form-label" id="emailLabel">E-mailadres</label>
            <input type="email" name="email" id="email" class="form-control" aria-labelledby="emailLabel"
                   v-model="user.email">

            <span class="text-info">
                <i class="las la-info-circle"></i> Dit e-mailadres wordt ook gebruikt om in te loggen. Wanneer je het e-mailadres van de gebruiker
                wijzigt, wijzig je dus ook de login van de gebruiker.
            </span>
        </div>

        <div class="alert alert-info" v-if="user.id == null">
            Bij het aanmaken van de gebruiker kan geen wachtwoord worden ingesteld.
        </div>

        <button type="submit" class="btn btn-primary" @click="saveUser()"><i class="las la-save"></i> Gebruiker opslaan
        </button>
    </div>
</template>
<script>
import axios from 'axios'
import { usePush } from 'notivue'

export default {
    props: [
        'user',
        'roles'
    ],
    methods: {
        saveUser() {
            // push.info('test')

            if (this.user.id) {
                axios.patch('/api/users/' + this.user.id, this.user)
                    .then(response => {
                        console.log(response)
                    })
            } else {
                axios.post('/api/users', this.user)
                    .then(response => {
                        console.log(response)
                    })
            }
        }
    }
}
</script>
