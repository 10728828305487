<template>
    <div>
        <breadcrumbs></breadcrumbs>

        <notifications
            position="top center"
            width="100%"
            max="10"
            dangerouslySetInnerHtml="true"
            duration="3000"
        />
        <router-view></router-view>
    </div>
</template>
<script>
import { Notivue, Notifications } from 'notivue'
import axios from "axios";
import Breadcrumbs from './Breadcrumbs.vue'

export default {
    components: {
        Breadcrumbs
    },
    created() {
        if (localStorage && (localStorage.getItem('apiToken') == null || localStorage.getItem('apiToken') == 'null')) {
            console.info('Generating new API token')
            let token = null
            axios.post('/tokens/create')/*.then(resp => resp.json)*/
                .then((json) => {
                    console.log(json, json.data, json.data.token)
                    token = json.data.token
                    localStorage.setItem('apiToken', token)
                })


        }

        axios.defaults.withCredentials = true;
    }
}
</script>
