import $ from "jquery";

$('[data-clone]').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();

    let element = $($(this).data('clone')),
        parent  = $($(this).data('clone-append'));

    element.clone().removeClass('d-none').attr('rel', '').appendTo(parent);

    $('[data-remove-clone]').off('click').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        let element = $(this).parents($(this).data('remove-clone'));
        $(element).remove();
    });
});
