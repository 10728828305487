<template>
    <div class="card">
        <div class="card-body">
            <table class="table datatable">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th>Rol</th>
                    <th>Email</th>
                    <th>Projecten</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user">
                        <td v-if="can('Users::View')">
                            <router-link :to="{name: 'users.view', params: { id: user.id }}">
                                {{user.name}} <i class="text-primary las la-external-link-alt"></i>
                            </router-link>
                        </td>
                        <td v-else>{{user.name}}</td>
                        <td>{{user.role}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.subscriptions.length}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            users: []
        }
    },
    mounted() {
        this.emitter.emit('set-title', 'Gebruikers')

        fetch('/api/users')
            .then(resp => resp.json())
            .then(json => this.users = json)
    }
}
</script>
