<template>
    <div>
        <div class="alert alert-danger mt-2 mb-0" v-if="this.errorMessage != null && linkoffer == null" v-html="errorMessage" />

        <div class="card mb-5" v-if="linkoffer != null">
            <form action="" method="post" @submit.prevent="updateLinkoffer">
                <div class="card-body">
                    <div class="mb-3">
                        <label for="linkofferType" class="form-label">Link type</label>
                        <multiselect
                            v-model="linkoffer.type"
                            :options="types"
                            :searchable="false"
                            :can-clear="false"
                            :limit="10"
                            :loading="linkoffer == null"
                            id="linkofferType"
                        ></multiselect>
                    </div>

                    <div :class="{'mb-3': this.errorMessage != null && this.errorLocation == 'linkoffer'}">
                        <label for="brancheId" class="form-label">Branche</label>
                        <multiselect
                            v-model="brancheIds"
                            :options="branchesList"
                            :loading="branchesList == null"

                            :close-on-select="false"
                            :searchable="true"
                            :create-option="false"

                            mode="tags"
                            id="brancheId"
                        ></multiselect>
                    </div>

                    <div class="alert alert-danger mt-2 mb-0" v-if="this.errorMessage != null && this.errorLocation == 'linkoffer'" v-html="errorMessage" />
                </div>
                <div class="card-footer">
                    <div class="actions">
                        <button type="submit" class="btn btn-primary"><i class="las la-save"></i> Opslaan</button>
                    </div>
                </div>
            </form>
        </div>

        <h2 v-if="linkoffer != null && linkoffer.notes.length > 0">Notities</h2>

        <div class="card mb-3" v-if="linkoffer != null && linkoffer.notes.length > 0"
            v-for="note in linkoffer.notes" :key="note">
            <div class="card-header text-muted">
                {{ moment(String(note.created_at)).format('DD-MM-YYYY HH:mm') }}
            </div>
            <div class="card-body">
                {{ note.content }}
            </div>
        </div>

        <div class="card" v-if="linkoffer != null">
            <div class="card-header">
                <h4 class="card-title">Notitie toevoegen</h4>
            </div>
            <div class="card-body">
                <textarea v-model="newNote" placeholder="Notitie toevoegen" class="form-control" rows="6"></textarea>

                <div class="alert alert-success mt-2 mb-0" v-if="displaySuccess">
                    De notitie is succesvol toegevoegd
                </div>
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-primary" @click="saveNote()"
                    v-bind:disabled="(newNote != null && newNote.length < 1) || noteSaving == true">
                    <i class="las la-spinner fa-spin" v-if="noteSaving == true"></i><i class="las la-save" v-else></i> Notitie toevoegen
                </button>

                <div class="alert alert-danger mt-2 mb-0" v-if="this.errorMessage != null && this.errorLocation == 'note'" v-html="errorMessage" />
            </div>
        </div>

        <div class="page-loader" v-if="loading == true || noteSaving == true">
            Loading..
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import moment from "moment/moment";

export default {
    computed: {
        moment() {
            return moment
        }
    },
    components: {
        Multiselect
    },
    data() {
        return {
            types: {
                'startpage': 'Startpagina',
                'link': 'Website',
                'web2.0': 'Web 2.0',
                'bedrijvengids': 'Bedrijvengids',
                'linkfarm': 'Linkfarm',
                'forumlink': 'Forumlink',
                'nieuwssite': 'Nieuwssite'
            },
            linkoffer: null,
            newNote: '',
            noteSaving: false,
            displaySuccess: false,
            errorMessage: null,
            loading: true,
            branchesList: null,
            brancheIds: null,
            errorLocation: null,
        }
    },
    mounted() {
        this.loadLinkoffer()

        axios.defaults.headers.common = {
            'X-CSRF-TOKEN': Laravel.csrfToken,
            'X-Requested-With': 'XMLHttpRequest'
        };
    },
    methods: {
        afterSave(json) {
            this.linkoffer = json
            this.newNote = null
            this.displaySuccess = true
            this.noteSaving = false

            setTimeout(function () {
                this.displaySuccess = false
            }, 10000)
        },
        updateLinkoffer() {
            this.errorLocation = 'linkoffer'

            let data = {
                "action": "updateLinkoffer",
                "type": this.linkoffer.type,
                "brancheIds": this.brancheIds
            };

            axios.patch('/api/linkoffers/' + this.$route.params.id, data).then(resp => resp.json)
                .then(json => this.afterSave(json))
                .catch((error) => {
                    this.errorMessage = '<strong>Er is iets mis gegaan:</strong><br>' + error.message;
                    this.noteSaving = false
                    this.loadLinkoffer()
                })
        },
        saveNote() {
            this.noteSaving = true
            this.errorMessage = false
            this.errorLocation = 'note'

            let data = {
                "action": "saveNote",
                "note": this.newNote
            };

            axios.patch('/api/linkoffers/' + this.$route.params.id, data).then(resp => resp.json)
                .then(json => this.afterSave(json))
                .catch((error) => {
                    this.errorMessage = '<strong>Er is iets mis gegaan:</strong><br>' + error.message;
                    this.noteSaving = false
                    this.loadLinkoffer()
                })
        },
        loadLinkoffer() {
            this.loading = true
            fetch('/api/linkoffers/' + this.$route.params.id)
                .then(resp => resp.json())
                .then((json) => {
                    this.linkoffer = json
                    this.brancheIds = json.brancheIds
                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                    this.errorMessage = '<strong>Er is iets mis gegaan:</strong><br>' + error.message;
                    this.noteSaving = false
                })

            fetch('/api/branches')
                .then(resp => resp.json())
                .then(json => this.branchesList = json)
        }
    }
}
</script>
