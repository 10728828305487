<template>
    <form @submit.prevent="submitForm">
        <div class="card">
            <div class="card-body">
                <div class="mb-3">
                    <label for="websitesId" id="websitesIdLabel" class="form-label">
                        Website
                    </label>
                    <multiselect
                        v-model="websitesId"
                        :options="websites"
                        :searchable="true"
                        :loading="websites.length == 0"
                        :noOptionsText="this.noOptionsTextWebsites"
                        id="websitesId"
                        @change="loadSubscriptions()"
                    >
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon">&nbsp;{{ value.label }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon">&nbsp;{{ option.label }}
                        </template>
                    </multiselect>

                    <div class="alert alert-danger" v-if="errors != {} && errors.websites_id">{{ errors.websites_id }}</div>
                </div>

                <div class="mb-3">
                    <label for="subscriptionsId" id="subscriptionsIdLabel" class="form-label">Abonnement</label>
                    <multiselect
                        v-model="subscriptionsId"
                        :options="subscriptions"
                        :searchable="true"
                        :loading="subscriptions.length == 0"
                        :noOptionsText="this.noOptionsTextSubscriptions"
                        id="subscriptionsId"
                    >
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" v-if="value.icon">&nbsp;{{ value.label }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" v-if="option.icon">&nbsp;{{ option.label }}
                        </template>
                    </multiselect>

                    <div class="alert alert-danger" v-if="errors != {} && errors.subscriptions_id">{{ errors.subscriptions_id }}</div>
                </div>

                <div class="mb-3">
                    <label for="backlinkUrl" class="form-label" id="backlinkUrlLabel">Backlink URL</label>

                    <input type="url" class="form-control" id="backlinkUrl" v-model="backlinkUrl" placeholder="Backlink URL"
                        autocomplete="off" />

                    <div class="alert alert-danger" v-if="errors != {} && errors.backlink_url">{{ errors.backlink_url }}</div>
                </div>

                <div class="mb-3">
                    <label for="anchorUrl" class="form-label" id="anchorUrlLabel">Anker URL</label>

                    <input type="url" class="form-control" id="anchorUrl" v-model="anchorUrl" placeholder="Anker URL"
                           autocomplete="off" />

                    <div class="alert alert-danger" v-if="errors != {} && errors.anchor_url">{{ errors.anchor_url }}</div>
                </div>

                <div class="mb-3">
                    <label for="anchorText" class="form-label" id="anchorTextLabel">Ankertekst</label>

                    <input type="text" class="form-control" id="anchorText" v-model="anchorText" placeholder="Ankertekst"
                           autocomplete="off" />

                    <div class="alert alert-danger" v-if="errors != {} && errors.anchor_text">{{ errors.anchor_text }}</div>
                </div>

                <div class="mb-3">
                    <label for="linkpartnersId" id="linkpartnersIdLabel" class="form-label">Linkpartner</label>
                    <multiselect
                        v-model="linkpartnersId"
                        :options="linkpartners"
                        :searchable="true"
                        :loading="linkpartners.length == 0"
                        id="linkpartnersId"
                    >
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" v-if="value.icon">&nbsp;{{ value.label }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" v-if="option.icon">&nbsp;{{ option.label }}
                        </template>
                    </multiselect>

                    <span class="text-muted" v-if="!showNewLinkpartnerForm">
                        Staat de naam er niet tussen? <a href="#" @click.prevent="showNewLinkpartnerForm = true">Maak hier een nieuwe linkpartner!</a>
                    </span>

                    <div class="alert alert-danger" v-if="errors != {} && errors.linkpartner_id">{{ errors.linkpartner_id }}</div>
                </div>

                <div v-if="showNewLinkpartnerForm" class="mb-3">
                    <label for="newLinkpartnerName">Nieuwe Linkpartner Naam</label>
                    <input v-model="newLinkpartnerName" type="text" class="form-control" id="newLinkpartnerName" />
                </div>

                <div v-if="showNewLinkpartnerForm" class="mb-3">
                    <label for="newLinkpartnerEmail">Nieuwe Linkpartner E-mail adres</label>
                    <input v-model="newLinkpartnerEmail" type="text" class="form-control" id="newLinkpartnerEmail" />
                    <button @click.prevent="addNewLinkpartner" class="btn btn-primary mt-2">Toevoegen</button>
                </div>

                <div class="mb-3">
                    <label for="linkTypesId" id="linkTypesIdLabel" class="form-label">Type link</label>
                    <multiselect
                        v-model="linkTypesId"
                        :options="linkTypes"
                        :searchable="true"
                        :loading="linkTypes.length == 0"
                        id="linkTypesId"
                    >
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" v-if="value.icon">&nbsp;{{ value.label }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" v-if="option.icon">&nbsp;{{ option.label }}
                        </template>
                    </multiselect>

                    <div class="alert alert-danger" v-if="errors != {} && errors.link_types_id">{{ errors.link_types_id }}</div>
                </div>

                <div class="mb-3">
                    <label for="purchasePrice" class="form-label" id="purchasePriceLabel">Inkoopprijs</label>
                    <div class="input-group">
                        <span class="input-group-text">€</span>
                        <input type="number" class="form-control" id="purchasePrice" v-model="purchasePrice" placeholder="Inkoopprijs"
                               step=".1" autocomplete="off" />
                    </div>

                    <div class="alert alert-danger" v-if="errors != {} && errors.price">{{ errors.price }}</div>

                    <span class="text-muted" v-if="purchasePriceMargin > 0">
                    Over deze prijs wordt een marge van {{ purchasePriceMargin }}% berekend.
                        <span v-if="purchasePrice > 0">
                            Dat bekent dat er in dit geval een prijs van {{ new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(
                            purchasePrice * (1+ (purchasePriceMargin / 100))
                        ) }} naar de klant gefactureerd wordt of wordt afgetrokken van het mediabudget.
                        </span>
                    Laat leeg in geval van linkruil.
                    </span>
                </div>
            </div>

            <div class="card-footer">
                <button class="btn btn-primary" type="submit" :click="submitForm">
                    <i class="las la-save"></i> Opslaan
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Multiselect from "@vueform/multiselect";

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            websitesId: null,
            subscriptionsId: null,
            backlinkUrl: null,
            anchorUrl: null,
            anchorText: null,
            linkpartnersId: null,
            linkTypesId: null,
            purchasePrice: 0.00,
            purchasePriceMargin: 0.00,

            websites: [],
            subscriptions: [],
            linkpartners: [],
            linkTypes: [],

            validation: {
                errorFields: []
            },

            errors: {},

            noOptionsTextWebsites: 'Websites zijn niet geladen',
            noOptionsTextSubscriptions: 'Selecteer eerst een website met actieve abonnementen',

            showNewLinkpartnerForm: null,
            newLinkpartnerName: '',
            newLinkpartnerEmail: ''
        }
    },
    mounted() {
        this.emitter.emit('set-title', 'Nieuwe linkplaatsing')
        this.emitter.emit('set-breadcrumbs', [
            {
                label: 'Linkprofiel',
                url: '/linkbuilding'
            }
        ])

        if (this.$route.params.websiteId) {
            this.websitesId = this.$route.params.websiteId
        }

        this.loadWebsites()
        this.loadLinkpartners()
        this.loadLinkTypes()
    },

    methods: {
        submitForm() {
            this.validation = {
                errorFields: []
            }
            this.errors = {}
            this.$notify.close('form-error-notification')

            axios.post('/api/linkprofile', {
                websites_id: this.websitesId,
                subscriptions_id: this.subscriptionsId,
                backlink_url: this.backlinkUrl,
                anchor_url: this.anchorUrl,
                anchor_text: this.anchorText,
                linkpartner_id: this.linkpartnersId,
                link_type_id: this.linkTypesId,
                price: this.purchasePrice * (1+ (this.purchasePriceMargin / 100))
            }).then(response => {
                this.$notify({
                    type: 'success',
                    title: 'Linkplaatsing opgeslagen',
                    text: 'De linkplaatsing is succesvol opgeslagen.'
                });

                let id = response.data.websites_id

                this.websitesId = null
                this.subscriptionsId = null
                this.backlinkUrl = null
                this.anchorUrl = null
                this.anchorText = null
                this.linkpartnersId = null
                this.linkTypesId = null
                this.purchasePrice = 0

                setTimeout((id) => {
                    window.location = '/linkbuilding/website/' + id
                }, 1500, id)

            }).catch(error => {
                if (error.response && error.response.data && error.response.data.errors) {
                    console.log(error.response.data.errors)
                    let notificationText = '<ul>'

                    for (let field in error.response.data.errors) {
                        this.errors[field] = error.response.data.errors[field][0]
                        for (let i = 0; i < error.response.data.errors[field].length; i++) {
                            notificationText += '<li>' + error.response.data.errors[field][i] + '</li>'
                        }
                    }
                    notificationText += '</ul>'

                    this.$notify({
                        id: 'form-error-notification',
                        title: 'Er is iets fout gegaan bij het versturen van het formulier',
                        text: notificationText,
                        type: 'danger',
                        duration: '3000'
                    })
                    this.formErrors = error.response.data.errors
                }
            })
        },

        loadWebsites() {
            axios.get('/api/websites')
                .then(resp => {
                    this.websites = []
                    for (let w in resp.data) {
                        let website = resp.data[w]

                        this.websites.push({
                            'label': website.name,
                            'icon': website.favicon,
                            'value': website.id
                        })
                    }

                    if (this.websites.length == 1) {
                        this.websitesId = this.websites[0].value
                    }
                })
        },

        loadLinkpartners() {
            axios.get('/api/linkpartners')
                .then(resp => {
                    this.linkpartners = []

                    for (let l in resp.data) {
                        let partner = resp.data[l],
                            label = ''

                        if (partner.name != '' && partner.email != '') {
                            label = partner.name + ' <' + partner.email + '>'
                        } else if(partner.name == '' && partner.email != '') {
                            label = partner.email
                        } else {
                            label = '-- Onbekende linkpartner --'
                        }

                        this.linkpartners.push({
                            label: label,
                            value: partner.id
                        })
                    }
                })
        },

        loadSubscriptions(id) { 
            this.subscriptionsId = null
            this.subscriptions = []
            axios.get('/api/subscriptions?websites_id=' + id)
                .then(resp => {
                    for(let s in resp.data) {
                        let subscription = resp.data[s],
                            label = '';

                        if (subscription.type == 'seo') {
                            label = 'SEO abonnement';
                        } else if(subscription.type == 'media') {
                            label = 'Mediabudget'
                        } else if(subscription.type == 'sea') {
                            label = 'SEA abonnement'
                        } else if(subscription.type == 'lb') {
                            label = 'Linkbuilding abonnement'
                        } else {
                            label = '⚠️ Onbekend type abonnement'
                        }

                        label += ' ('

                        if (subscription.start_date && Date.parse(subscription.start_date) > 0) {
                            label += moment(subscription.start_date).format('DD-MM-YYYY')
                        } else {
                            let suffix = ''
                            if (subscription.type == 'media') {
                                suffix = '€ ' + subscription.budget
                            } else {
                                suffix = subscription.budget + ' uur'
                            }
                            label += 'Vast budget: ' + suffix
                        }

                        if (subscription.end_date && Date.parse(subscription.end_date) > 0) {
                            label += ' -  ' + moment(subscription.end_date).format('DD-MM-YYYY')
                        }

                        label += ')'

                        this.subscriptions.push({
                            label: label,
                            value: subscription.id
                        })
                    }
                })
        },

        loadLinkTypes() {
            this.linkTypes = []
            axios.get('/api/linkpartnerstypes')
                .then(resp => {
                    for (let t in resp.data) {
                        let type = resp.data[t]

                        this.linkTypes.push({
                            label: type.label,
                            value: type.id
                        })
                    }
                })
        },

        loadCustomer(id) {
            axios.get('/api/websites/' + id)
                .then(resp => {
                    let customer = resp.data.customer
                    this.purchasePriceMargin = customer.budget_margin
                })
        },

        addNewLinkpartner() {
            if (this.newLinkpartnerName && this.newLinkpartnerEmail) {

                console.log({
                    name: this.newLinkpartnerName,
                    email: this.newLinkpartnerEmail
                })
                
                axios.post('/api/linkpartners', {
                    name: this.newLinkpartnerName,
                    email: this.newLinkpartnerEmail
                })
                .then(response => {
                    const newLinkpartner = response.data;
                    this.loadLinkpartners()
                    this.newLinkpartnerName = '';
                    this.newLinkpartnerEmail = '',
                    this.showNewLinkpartnerForm = false;
                    this.linkpartnersId = newLinkpartner.id;
                })
                .catch(error => {
                    console.error('Er is een fout opgetreden bij het toevoegen van de nieuwe linkpartner', error);
                });
            }
        }
    },

    watch: {
        websitesId: {
            handler(newValue) {
                this.loadSubscriptions(newValue)
                this.loadCustomer(newValue)
            },
            deep: true
        }
    }


}
</script>
