<template>
    <div class="d-flex align-items-between">
        <div class="py-3 breadcrumb-wrapper mb-4">
            <span class="text-muted fw-light" v-for="breadcrumb in breadcrumbs" :key="breadcrumb.url">
                <a v-if="breadcrumb.url" :href="breadcrumb.url" class="text-muted" v-html="breadcrumb.label" />
                <router-link class="text-muted" v-if="breadcrumb.route" :to="breadcrumb.route" v-html="breadcrumb.label" />
                <i class="las la-angle-right"></i>
            </span>

            <h1 class="my-0" v-html="title" />
        </div>
        <div v-if="actions != ''" class="actions flex-shrink-1" style="margin-left: auto;" v-html="actions" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            breadcrumbs: [],
            title: 'Default title',
            actions: ''
        }
    },
    mounted() {
        this.emitter.on('set-breadcrumbs', (data) => {
            this.breadcrumbs = data
        })
        this.emitter.on('set-title', (title) => {
            this.title = title
        })
        this.emitter.on('set-actions', (actions) => {
            this.actions = actions
        })
    }
}
</script>
