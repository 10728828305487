<template>
        <form @submit.prevent="handleSubmit">
            <div class="card mb-3">
            <div class="card-body">
                <div class="mb-3">
                    <label for="name" class="form-label">Naam klant</label>
                    <input v-model="formData.name" type="text" name="name" id="name" class="form-control">

                    <div class="alert alert-danger" v-if="formErrors.hasOwnProperty('name')">
                        <ul class="mb-0">
                            <li v-for="error in formErrors.name" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="street_address" class="form-label">Adres</label>
                    <input v-model="formData.street_address" type="text" name="street_address" id="street_address" class="form-control">

                    <div class="alert alert-danger" v-if="formErrors.hasOwnProperty('street_address')">
                        <ul class="mb-0">
                            <li v-for="error in formErrors.street_address" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4">
                        <label for="postcode" class="form-label">Postcode</label>
                        <input v-model="formData.postcode" type="text" name="postcode" id="postcode" class="form-control">

                        <div class="alert alert-danger" v-if="formErrors.hasOwnProperty('postcode')">
                            <ul class="mb-0">
                                <li v-for="error in formErrors.postcode" :key="error" class="mb-0">{{ error }}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-8">
                        <label for="city" class="form-label">Plaats</label>
                        <input v-model="formData.city" type="text" name="city" id="city" class="form-control">

                        <div class="alert alert-danger" v-if="formErrors.hasOwnProperty('city')">
                            <ul class="mb-0">
                                <li v-for="error in formErrors.city" :key="error" class="mb-0">{{ error }}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="budget_margin" class="form-label">marge</label>
                    <input v-model="formData.budget_margin" type="text" placeholder="%" name="budget_margin" id="budget_margin" class="form-control">
                    

                    <div class="alert alert-danger" v-if="formErrors.hasOwnProperty('budget_margin')">
                        <ul class="mb-0">
                            <li v-for="error in formErrors.budget_margin" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div id="websites" v-if="this.editMode == false">
            <div v-for="(website, index) in formData.websites" :key="index" class="card website mb-3">
                <div class="card-header">
                    <h4 class="card-title">Website toevoegen</h4>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label :for="'website' + index + 'Name'" class="form-label">Naam</label>
                        <input v-model="website.name" :name="'website[' + index + '][name]'" :id="'website' + index + 'Name'" class="form-control">
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex">
            <div class="flex-grow-1">
                <button @click="addWebsite" type="button" class="btn btn-outline-success" v-if="this.editMode == false">
                    <i class="las la-plus"></i> Website toevoegen
                </button>

                <button type="button" class="btn btn-danger" v-if="this.editMode == true">
                    <i class="las la-trash"></i> Verwijderen
                </button>
            </div>
            <div class="flex-shrink-1 flex-grow-0">
                <button type="submit" class="btn btn-primary">
                    <i class="las la-save"></i> Opslaan
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            formData: {
                name: '',
                street_address: '',
                postcode: '',
                budget_margin: '',
                city: '',
                websites: []
            },
            formErrors: {},
            editMode: false
        };
    },
    mounted() {
        if (this.$route.params.id) {

            axios.get('/api/customers/' + this.$route.params.id)
                .then(resp => {
                    let json = resp.data

                    this.formData.name = json.name
                    this.formData.street_address = json.street_address
                    this.formData.budget_margin = json.budget_margin
                    this.formData.postcode = json.postcode
                    this.formData.city = json.city
                    this.formData.websites = json.websites

                    this.user = json

                    this.emitter.emit('set-breadcrumbs', [
                        {
                            label: 'Klanten',
                            route: {name: 'customers.overview'}
                        },
                        {
                            label: json.name,
                           /* route: {name: 'customers.view', params: {id: json.id}} */
                        }
                    ])

                    this.editMode = true
                })

            this.emitter.emit('set-title', 'Bewerken')
        } else {
            this.emitter.emit('set-breadcrumbs', [
                {
                    label: 'Klanten',
                    route: {name: 'customers.overview'}
                }
            ])

            this.emitter.emit('set-title', 'Nieuwe klant')
        }
    },
    methods: {
        addWebsite() {
            this.formData.websites.push({ name: '' });
        },
        handleSubmit() {
            console.log("Form submitted");

            // Handle form submission
            if (this.formData) {
                this.errors = {}
                if (this.editMode == false) {
                    axios.post('/api/customers', this.formData)
                        .then(response => {
                            if (response.data.id) {
                                this.$notify({
                                    text: 'Klant ' + this.formData.name + ' succesvol aangemaakt',
                                    type: 'success',
                                    duration: '3000'
                                })
                                this.$router.push({name: 'customers.view', params: {id: response.data.id}})
                            }
                        })
                        .catch(error => {
                            if (error.response && error.response.data && error.response.data.errors) {
                                console.log(error.response.data.errors)
                                let notificationText = '<ul>'

                                for (let field in error.response.data.errors) {
                                    for (let i = 0; i < error.response.data.errors[field].length; i++) {
                                        notificationText += '<li>' + error.response.data.errors[field][i] + '</li>'
                                    }
                                }
                                notificationText += '</ul>'

                                this.$notify({
                                    title: 'Er is iets fout gegaan bij het versturen van het formulier',
                                    text: notificationText,
                                    type: 'danger',
                                    duration: '3000'
                                })
                                this.formErrors = error.response.data.errors
                            }
                        })
                } else {
                    axios.patch('/api/customers/' + this.$route.params.id, this.formData)
                        .then(response => {
                            if (response.data.id) {
                                this.$notify({
                                    text: 'Klant ' + this.formData.name + ' opgeslagen',
                                    type: 'success',
                                    duration: '3000'
                                })
                                this.$router.push({name: 'customers.view', params: {id: response.data.id}})
                            }
                        })
                        .catch(error => {
                            if (error.response && error.response.data && error.response.data.errors) {
                                console.log(error.response.data.errors)
                                let notificationText = '<ul>'

                                for (let field in error.response.data.errors) {
                                    for (let i = 0; i < error.response.data.errors[field].length; i++) {
                                        notificationText += '<li>' + error.response.data.errors[field][i] + '</li>'
                                    }
                                }
                                notificationText += '</ul>'

                                this.$notify({
                                    title: 'Er is iets fout gegaan bij het versturen van het formulier',
                                    text: notificationText,
                                    type: 'danger',
                                    duration: '3000'
                                })
                                this.formErrors = error.response.data.errors
                            }
                        })
                }
            } else {

            }
        }
    }
};
</script>

<style>
/* Add your component-specific styles here */
</style>
