<template>
    <div>
        <ul class="nav nav-pills">
            <li class="nav-item">
                <a href="#tabNotInvoiced" class="nav-link" data-current="page" data-bs-toggle="tab"
                   data-bs-target="#tabNotInvoiced"
                   :class="{'active': (activeTab == 'not-invoiced')}"
                   @click="setActiveTab('not-invoiced')">
                    Niet gefactureerde linkplaatsingen
                </a>
            </li>
            <li class="nav-item">
                <a href="#tabInvoiced" class="nav-link" data-current="page" data-bs-toggle="tab"
                   data-bs-target="#tabInvoiced"
                   :class="{'active': (activeTab == 'invoiced')}"
                   @click="setActiveTab('invoiced')">
                    Gefactureerde linkplaatsingen
                </a>
            </li>
        </ul>


        <div class="tab-content px-0">
            <div class="tab-pane fade" id="tabNotInvoiced"
                 :class="{'show active': (activeTab == 'not-invoiced')}">
                <div class="card">
                    <div class="card-body">
                        {{ notInvoiced }}
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="Invoiced"
                 :class="{'show active': (activeTab == 'invoiced')}">
                <div class="card">
                    <div class="card-body">
                        tab 2
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            activeTab: 'not-invoiced',
            notInvoiced: [],
            invoiced: []
        }
    },
    mounted() {
        this.emitter.emit('set-title', 'Factureerbare linkplaatsingen')

        if (window.location.hash == '#tabInvoiced') {
            this.activeTab = 'invoiced'
        }

        this.refreshData()
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab
            return false
        },

        refreshData() {
            axios.get('/api/linkprofile/not-invoiced').then(response => {
                this.notInvoiced = response.data
            })

            axios.get('/api/linkprofile/invoiced').then(response => {
                this.invoiced = response.data
            })
        }
    }
}
</script>
