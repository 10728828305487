import { createApp } from 'vue/dist/vue.esm-bundler'
import { createRouter, createWebHistory } from 'vue-router'
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs'
import DataTable from 'datatables.net-vue3'
import Notifications from '@kyvg/vue3-notification'
import Breadcrumbs from './components/Breadcrumbs.vue'
import mitt from 'mitt';

import moment from "moment"
// import Select from 'datatables.net-select';

import App from './components/App.vue'
import UserOverview from './components/user/Overview.vue'
import UserView from './components/user/View.vue'
import UserEdit from './components/user/Edit.vue'
import LinkoffersOverview from './components/linkoffers/Overview.vue'
import LinkoffersEdit from './components/linkoffers/Edit.vue'
import SubscriptionsForm from './components/subscriptions/Form.vue'
import CustomerCreate from './components/customers/Create.vue'
import CustomerView from './components/customers/View.vue'
import CustomerOverview from './components/customers/Overview.vue'
import LinkbuildingForm from './components/linkbuilding/Form.vue'
import LinkbuildingInvoiceable from './components/linkbuilding/Invoiceable.vue'

import 'notivue/notifications.css' // Only needed if using built-in notifications
import 'notivue/animations.css' // Only needed if using built-in animations

const emitter = mitt();

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: 'customers.overview',
            path: '/customers',
            component: CustomerOverview
        },
        {
            name: 'customers.create',
            path: '/customer/create',
            component: CustomerCreate
        },
/*        {
            name: 'customers.view',
            path: '/customer/:id',
            component: CustomerView
        },*/
        {
            name: 'customers.edit',
            path: '/customer/:id/edit',
            component: CustomerCreate
        },



        {
            name: 'users.overview',
            path: '/users',
            component: UserOverview
        },
        {
            name: 'users.view',
            path: '/user/:id',
            component: UserView,
            props: true
        },
        {
            name: 'users.edit',
            path: '/user/:id/edit',
            component: UserEdit
        },

        {
            name: 'linkoffers.overview',
            path: '/linkoffers',
            component: LinkoffersOverview
        },
        {
            name: 'linkoffers.edit',
            path: '/linkoffer/:id/edit',
            component: LinkoffersEdit
        },
        {
            name: 'subscriptions.create',
            path: '/subscription/create',
            component: SubscriptionsForm
        },
        {
            name: 'subscriptions.createForWebsite',
            path: '/subscription/create/website/:websitesId',
            component: SubscriptionsForm
        },
        {
            name: 'subscriptions.createForCustomer',
            path: '/subscription/create/customer/:customerId',
            component: SubscriptionsForm
        },
        {
            name: 'subscriptions.edit',
            path: '/subscription/:id/edit',
            component: SubscriptionsForm
        },

        {
            name: 'linkbuilding.create',
            path: '/linkbuilding/create',
            component: LinkbuildingForm
        },
        {
            name: 'linkbuilding.create.website',
            path: '/linkbuilding/website/:websiteId/create',
            component: LinkbuildingForm
        },
        {
            name: 'linkbuilding.edit',
            path: '/linkbuilding/:id/edit',
            component: LinkbuildingForm
        },
        {
            name: 'linkbuilding.invoiceable',
            path: '/linkbuilding/invoiceable',
            component: LinkbuildingInvoiceable
        }
    ]
})

const app = createApp(App)
    .use(router)
    .use(LaravelPermissionToVueJS)
    .use(DataTable)
    .use(Notifications)

app.config.globalProperties.emitter = emitter;

app.mount('#vueApp')
