<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a href="#tabFree" class="nav-link" data-current="page" data-bs-toggle="tab"
                           data-bs-target="#tabFree"
                           :class="{'active': (activeTab == 'free')}"
                           @click="setActiveTab('free')">
                            Linkruilmogelijkheden
                            <span v-if="freeCount > 0">({{ freeCount }})</span>
                            <span v-if="freeCount == 0"><i class="las la-spinner fa-spin"></i></span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#tabPaid" class="nav-link" data-bs-toggle="tab" data-bs-target="#tabPaid"
                           :class="{'active': (activeTab == 'paid')}"
                           @click="setActiveTab('paid')">
                            Betaalde mogelijkheden
                            <span v-if="paidCount > 0">({{ paidCount }})</span>
                            <span v-if="paidCount == 0"><i class="las la-spinner fa-spin"></i></span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#tabStartpage" class="nav-link" data-bs-toggle="tab" data-bs-target="#tabStartpage"
                           :class="{'active': (activeTab == 'startpage')}"
                           @click="setActiveTab('startpage')">
                            Startpagina's
                            <span v-if="startpageCount > 0">({{ startpageCount }})</span>
                            <span v-if="startpageCount == 0"><i class="las la-spinner fa-spin"></i></span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#tabWeb20" class="nav-link" data-bs-toggle="tab" data-bs-target="#tabWeb20"
                           :class="{'active': (activeTab == 'web20')}"
                           @click="setActiveTab('web20')">
                            Web 2.0
                            <span v-if="web20Count > 0">({{ web20Count }})</span>
                            <span v-if="web20Count == 0"><i class="las la-spinner fa-spin"></i></span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a href="#tabFault" class="nav-link" data-bs-toggle="tab" data-bs-target="#tabFault"
                           :class="{'active': (activeTab == 'fault')}"
                           @click="setActiveTab('fault')">
                            Overig
                            <span v-if="faultCount > 0">({{ faultCount }})</span>
                            <span v-if="faultCount == 0"><i class="las la-spinner fa-spin"></i></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="mt-4 tab-content py-0 px-0">
            <div class="tab-pane fade" id="tabFree"
                 v-bind:class="{'show active': (activeTab == 'free')}">
                <div class="card">
                    <div class="card-body table-responsive">
                        <linkoffers-table :url="routes.free" :columns="columns.free" v-if="routes.free != ''"
                                          :tableId="'linkOffersFree'"
                                          :branchesList="branchesList"></linkoffers-table>
                        <div class="page-loader" v-if="routes.free == ''"></div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="tabPaid"
                 v-bind:class="{'show active': (activeTab == 'paid')}">
                <div class="card">
                    <div class="card-body table-responsive">
                        <linkoffers-table :url="routes.paid" :columns="columns.paid" v-if="routes.paid != ''"
                                          :tableId="'linkOffersPaid'"
                                          :branchesList="branchesList"></linkoffers-table>
                        <div class="page-loader" v-if="routes.paid == ''"></div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="tabStartpage"
                 v-bind:class="{'show active': (activeTab == 'startpage')}">
                <div class="card">
                    <div class="card-body table-responsive">
                        <linkoffers-table :url="routes.startpage" :columns="columns.startpage"
                                          v-if="routes.startpage != ''" :tableId="'linkOffersStartpage'"
                                          :branchesList="branchesList"></linkoffers-table>
                        <div class="page-loader" v-if="routes.startpage == ''"></div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="tabWeb20"
                 v-bind:class="{'show active': (activeTab == 'web20')}">
                <div class="card">
                    <div class="card-body table-responsive">
                        <linkoffers-table :url="routes.web20" :columns="columns.startpage"
                                          v-if="routes.web20 != ''" :tableId="'linkOffersWeb20'"
                                          :branchesList="branchesList"></linkoffers-table>
                        <div class="page-loader" v-if="routes.web20 == ''"></div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="tabFault"
                 v-bind:class="{'show active': (activeTab == 'fault')}">
                <div class="card">
                    <div class="alert alert-info d-flex align-items-center" style="gap:8px;">
                        <i class="las la-info-circle"></i>
                        <p class="ml-3 mb-0">
                            Overig aanbod is aanbod die niet tussen de linkruilmogelijkheden en niet tussen de betaalde
                            mogelijkheden passen. Dit wordt veroorzaakt doordat ze niet aangemerkt staan als een optie
                            om te ruilen en niet aangemerkt staan als een betaalde optie. Dit is waarschijnlijk tijdens
                            het importeren van het aanbod verkeerd gegaan.
                        </p>
                    </div>
                    <div class="card-body table-responsive">
                        <linkoffers-table :url="routes.fault" :columns="columns.startpage"
                                          v-if="routes.fault != ''" :tableId="'linkOffersFault'"
                                          :branchesList="branchesList"></linkoffers-table>
                        <div class="page-loader" v-if="routes.web20 == ''"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LinkoffersTable from './Table.vue'

export default {
    components: {
        LinkoffersTable
    },
    data() {
        return {
            branchesList: [],
            activeTab: 'free',
            routes: {
                free: '',
                fault: '',
                paid: '',
                startpage: '',
                web20: ''
            },
            freeCount: 0,
            faultCount: 0,
            paidCount: 0,
            startpageCount: 0,
            web20Count: 0,
            columns: {
                free: [
                    {data: 'website', orderable: true, title: 'Website', type: 'html', filterInput: true},
                    {data: 'ext', orderable: true, title: 'Ext.', type: 'string', filterInput: true},
                    {data: 'branche', orderable: true, title: 'Branche', type: 'string', searchable: true, filterBranches: true},
                    {data: 'da', orderable: true, title: 'DA', type: 'num'},
                    {data: 'visitors', orderable: true, title: 'Bezoekers', type: 'num', filterInput: true},
                    {data: 'linkpartner', orderable: false, title: 'Linkpartner', filterInput: true},
                    {data: 'paid_possible', orderable: false, title: 'Betaald mogelijk'},
                    {data: 'exchange_possible', orderable: false, title: 'Ruilen mogelijk'},
                    {data: 'blog_possible', orderable: false, title: 'Blog mogelijk'},
                    {data: 'note', orderable: false, title: 'Notities'},
                    {data: 'actions', orderable: false, title: 'Acties'}
                ],
                paid: [
                    {data: 'website', orderable: true, title: 'Website', type: 'html', filterInput: true},
                    {data: 'ext', orderable: true, title: 'Ext.', type: 'string', filterInput: true},
                    {data: 'branche', orderable: true, title: 'Branche', type: 'string', searchable: true, filterBranches: true},
                    {data: 'da', orderable: true, title: 'DA'},
                    {data: 'visitors', orderable: true, title: 'Bezoekers', type: 'num'},
                    {data: 'linkpartner', orderable: false, title: 'Linkpartner', filterInput: true},
                    {data: 'exchange_possible', orderable: false, title: 'Ruilen mogelijk'},
                    {data: 'blog_possible', orderable: false, title: 'Blog mogelijk'},
                    {data: 'textlink', orderable: true, title: 'Prijs tekstlink'},
                    {data: 'price_excl_writing', orderable: true, title: 'Prijs excl. schrijven'},
                    {data: 'price_incl_writing', orderable: true, title: 'Prijs incl. schrijven'},
                    {data: 'price_niche_edit', orderable: true, title: 'Prijs niche-edit'},
                    {data: 'advertorial_notice', orderable: true, title: 'Advertorial melding'},
                    {data: 'note', orderable: false, title: 'Notities'},
                    {data: 'actions', orderable: false, title: 'Acties'}
                ],
                startpage: [
                    {data: 'website', orderable: true, title: 'Website', type: 'html', filterInput: true},
                    {data: 'ext', orderable: true, title: 'Ext.', type: 'string', filterInput: true},
                    {data: 'branche', orderable: true, title: 'Branche', type: 'string', searchable: true, filterBranches: true},
                    {data: 'da', orderable: true, title: 'DA'},
                    {data: 'visitors', orderable: true, title: 'Bezoekers', type: 'num'},
                    {data: 'linkpartner', orderable: false, title: 'Linkpartner', filterInput: true},
                    {data: 'paid_possible', orderable: false, title: 'Betaald mogelijk'},
                    {data: 'exchange_possible', orderable: false, title: 'Ruilen mogelijk'},
                    {data: 'blog_possible', orderable: false, title: 'Blog mogelijk'},
                    {data: 'note', orderable: false, title: 'Notities'},
                    {data: 'actions', orderable: false, title: 'Acties'}
                ],
            }
        }
    },
    mounted() {
        this.emitter.emit('set-title', 'Linkaanbod <i class="las la-spinner fa-spin"></i>')
        // document.getElementsByTagName('h1')[0].innerHTML += ' <i class="las la-spinner fa-spin"></i>'

        fetch('/api/linkoffers/routes')
            .then(resp => resp.json())
            .then(json => this.routes = json)

        fetch('/api/linkoffers/counts')
            .then(resp => resp.json())
            .then((json) => {
                this.freeCount = json.freeCount
                this.faultCount = json.faultCount
                this.paidCount = json.paidCount
                this.startpageCount = json.startpageCount
                this.web20Count = json.web20Count

                let offersCount = parseInt(json.totalCount)
                this.emitter.emit('set-title', 'Linkaanbod (' + offersCount + ')')
            })

        fetch('/api/branches')
            .then(resp => resp.json())
            .then(json => this.branchesList = json)
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab;
        }
    }
}
</script>
