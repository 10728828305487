<template>
    <div>
        <div class="card" v-if="roles != []">
            <div class="card-body">
                <user-form :user="user" :roles="roles"></user-form>
            </div>

            <div class="card-footer">
                <a href="{ route('users.delete', ['user' => $user]) }" class="btn btn-danger">
                    <i class="las la-archive"></i>
                    Gebruiker archiveren
                </a>
            </div>
        </div>

        <div class="page-loader" v-if="roles == []">
            Loading..
        </div>
    </div>
</template>

<script>
import UserForm from './Form.vue'

export default {
    components: {
        UserForm
    },
    data() {
        return {
            user: [],
            roles: []
        }
    },
    mounted() {
        fetch('/api/users/' + this.$route.params.id)
            .then(resp => resp.json())
            .then(json => this.user = json)

        fetch('/api/roles')
            .then(resp => resp.json())
            .then(json => this.roles = json)
    }
}
</script>
