<template>
    <div>
        <router-link :to="{name: 'customers.create'}" class="btn btn-primary text-nowrap float-end"
                     style="margin-top: -88px;">
            <i class="las la-plus"></i> Nieuwe klant
        </router-link>

        <div class="card" @click="closeDropdowns()">
            <div class="card-body">
                <table class="table datatable-with-counter">

                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Naam klant</th>
                        <th>Websites</th>
                        <th>Acties</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="filter-input"></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="customer in customers" :key="customer.id">
                        <td>{{ customer.id }}</td>
                        <td>
                            <router-link :to="{name: 'customers.view', params: {id: customer.id}}"
                                         class="text-body d-block">
                                {{ customer.name }}

                                <i class="las la-external-link-alt text-primary"></i>
                            </router-link>
                        </td>
                        <td>
                            {{ customer.websites.length }}
                        </td>
                        <td>
                            <button class="btn btn-sm btn-secondary dropdown-toggle" type="button"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                    @click="toggleDropdown($event, customer.id)">
                                Acties
                            </button>

                            <ul class="dropdown-menu" :class="{'show': displayDropdown[customer.id]}"
                                aria-labelledby="dropdownMenuButton1">
                                <li @click="toggleDropdown($event, customer.id)">
                                    <router-link :to="{name: 'customers.view', params: {id: customer.id}}"
                                                 class="dropdown-item">
                                        <i class="fa fa-eye"></i> Bekijken
                                    </router-link>
                                </li>
                                <li v-if="can('Customers::Edit')" @click="toggleDropdown($event, customer.id)">
                                    <router-link :to="{name: 'customers.edit', params: {id: customer.id}}"
                                                 class="dropdown-item">
                                        <i class="las la-pen"></i> Bewerken
                                    </router-link>
                                </li>
                                <li v-if="can('Customers::Delete')" @click="toggleDropdown($event, customer.id)">
                                    <hr class="dropdown-divider">
                                </li>
                                <li v-if="can('Customers::Delete')" @click="toggleDropdown($event, customer.id)">
                                    <a href="#" class="dropdown-item text-danger">
                                        <i class="las la-trash"></i> Verwijderen
                                    </a>
                                    <!-- <a class="dropdown-item text-danger" href="{{ route('customers.delete', ['customer' => $customer->id]) }}"><i class="las la-trash"></i> Verwijderen</a> -->
                                </li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>#</th>
                        <th>Naam klant</th>
                        <th>Websites</th>
                        <th>Acties</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            customers: [],
            displayDropdown: []
        }
    },
    mounted() {
        this.emitter.emit('set-title', 'Klanten')

        fetch('/api/customers')
            .then(resp => resp.json())
            .then(json => {
                this.customers = json

                for (let customer in json) {
                    this.displayDropdown[customer.id] = false
                }
            })
    },
    methods: {
        toggleDropdown(event, id) {
            this.displayDropdown[id] = !this.displayDropdown[id]

            for (let customer in this.displayDropdown) {
                if (customer != id) {
                    this.displayDropdown[customer] = false
                }
            }

            event.stopPropagation()
        },
        closeDropdowns() {
            for (let customer in this.displayDropdown) {
                this.displayDropdown[customer] = false
            }
        }
    }
}
</script>
