// Import jQuery from library
import jQuery from "jquery";
// import * as $ from "jquery";
let $ = jQuery;

jQuery(() => {
    $('.custom-filter-table').each(function() {
        let $data = {
            page: 1,
            limit: $(this).data('limit') ? $(this).data('limit') : 100,
            order: $(this).data('order'),
            search: {}
        };

        $(this).data('load-data', $data)

        loadFilterTable($(this), $data);
    })
});

function loadFilterTable($table, $data) {
    let $url = $table.data('url');
    // $table.css({height: $table.outerHeight()});
    $table.html('');
    $table.addClass('loading');

    $.ajax({
        url: $url,
        data: $data,
        success: function(resp) {
            $table.html('');
            let $headers = resp.headings;
            _createFilterTableHeaders($headers, $table, resp.searchTerm)
            _createFilterTableRows(resp.data, $table);
            _createFilterTableFooter(resp, $table);
            _createFilterTableFilters(resp, $table);

            $table.removeClass('loading');
            $table.css({height: ''});
        }
    })
}

function _createFilterTableHeaders($headers, $table, $searchTerm) {
    let _headers = []

    for (let $header in $headers) {
        let _header = $headers[$header];
        _header.key = $header;
        _headers[_header.index] = _header;
    }

    $('<thead><tr></tr></thead>').prependTo($table);
    $('<tfoot><tr></tr></tfoot>').appendTo($table);
    $table.data('headers', _headers);

    for(let header in _headers) {
        let _header = _headers[header],
            classes = '';

        if(_header.sortable) {
            classes += ' sortable';
        }

        let _html = '<th data-index="' + _header.index + '" class="' + classes + '">' + _header.label + '</th>';
        $(_html).appendTo($('thead tr', $table));
        $(_html).appendTo($('tfoot tr', $table));
    }

    let _html = "<tr><td colspan='" + _headers.length + "'>Zoeken: <input type='text' name='term' value='" + $searchTerm + "' data-filter-column='term' /></td></tr>";
    $(_html).prependTo($('thead', $table));

    // console.log(_headers);
}

function _createFilterTableRows($data, $table) {
    let $headers = $table.data('headers');

    if ($('tbody', $table).length == 0) {
        $('<tbody></tbody>').appendTo($table);
    }

    for(let i = 0; i < $data.length; i++) {
        let $element = $data[i];
        let html = '<tr>'
        for(let header in $headers) {
            let $header = $headers[header];
            let $key = $header.key;

            html += '<td data-column="' + $key + '">' + $element[$key] + '</td>';
        }
        html += '</tr>';
        $(html).appendTo($('tbody', $table));
    }
}

function _createFilterTableFooter(resp, $table) {
    let $pages = resp.pages,
        $curPage = resp.curPage;

    $('ul.pagination[data-table-id=' + $table.attr('id') + ']').remove();

    let html = "<ul class='pagination' data-table-id='" + $table.attr('id') + "'>";
    html += "<li class='page-item";
    if ($curPage <= 1) { html += ' disabled'; }
    html += "'><a class='page-link' href='#' data-filter-table-navigation='prev'>Vorige</a></li>";

    if ($pages >= 9) {
        for(let $i = 1; $i <= 3; $i++) {
            html += "<li class='page-item";
            if ($i == $curPage) { html += ' active'; }
            html += "'><a class='page-link' href='#' data-filter-table-navigation='" + $i + "'>" + $i + "</a></li>";
        }
        if ($curPage > 3) {
            html += "<li>...</li>";

            for (let $i = (($curPage - 4 > 3) ? $curPage - 4 : 4); $i <= parseInt($curPage) + 4; $i++) {
                html += "<li class='page-item";
                if ($i == $curPage) { html += ' active'; }
                html += "'><a class='page-link' href='#' data-filter-table-navigation='" + $i + "'>" + $i + "</a></li>";
            }
        }

        if ($curPage < $pages - 3) {
            html += "<li>...</li>";
            for (let $i = $pages - 2; $i <= $pages; $i++) {
                html += "<li class='page-item";
                if ($i == $curPage) { html += ' active'; }
                html += "'><a class='page-link' href='#' data-filter-table-navigation='" + $i + "'>" + $i + "</a></li>";
            }
        }
    }

    html += "<li class='page-item";
    if (!($pages > $curPage)) { html += ' disabled'; }
    html += "'><a class='page-link' href='#' data-filter-table-navigation='next'>Volgende</a></li>";

    html += "</ul>";

    $table.data('pages', $pages);
    $table.data('curPage', $curPage);

    $(html).insertAfter($table);
    $(html).insertBefore($table);

    $('ul.pagination[data-table-id=' + $table.attr('id') + '] a').off('click')
        .on('click', function(e) {
            e.preventDefault()
            e.stopPropagation()

            // console.log($(this).parent().parent(), $(this).parent().parent().data('table-id'))

            let $table = $('#' + $(this).parent().parent().data('table-id')),
                $nextPage = parseInt($table.data('curPage')) + 1,
                $prevPage = parseInt($table.data('curPage')) - 1,
                $goTo = $(this).data('filter-table-navigation');

            if ($goTo == 'next') {
                $goTo = $nextPage;
            }
            if ($goTo == 'prev') {
                $goTo = $prevPage;
            }

            let $data = $table.data('load-data')
            $data.page = $goTo
            $table.data('load-data', $data)

            loadFilterTable($table, $data);
            // console.log($table, $nextPage, $prevPage, parseInt($table.data('curPage')))
        })
}

function _createFilterTableFilters(resp, $table) {
    let $filterHtml = "<tr>";
    let $tableData = $table.data('load-data')
    let $resp = resp;

    for(let $idx in resp.headings) {
        let $column = resp.headings[$idx],
            $columnFilterHtml = '',
            value;

        switch($column.filter) {
            case 'text':
                value = $resp.request.search.hasOwnProperty($column.key) && $resp.request.search[$column.key] != null ? $resp.request.search[$column.key] : '';
                $columnFilterHtml = '<input type="text" data-filter-column="' + $column.key + '" name="' + $column.key + '" value="' + value + '">'
                break
            case 'number':
                let $min = 999999;
                let $max = 0;
                for(let $n in resp.data) {
                    let $key = $column.key,
                        $data = parseInt(resp.data[$n][$key], 10);

                    if ($data < $min) $min = $data
                    if ($data > $max) $max = $data
                }

                value = {
                    min: ($resp.request.search.hasOwnProperty($column.key)) ? $resp.request.search[$column.key] : null,
                    max: ($resp.request.search.hasOwnProperty($column.key)) ? $resp.request.search[$column.key] : null,
                }
                $columnFilterHtml = 'van <input type="number" data-filter-column="' + $column.key + '" min="' + $min + '" max="' + $max + '" name="' + $column.key + '[min]]" value="' + value.min + '"><br>';
                $columnFilterHtml += 'tot <input type="number" data-filter-column="' + $column.key + '" min="' + $min + '" max="' + $max + '" name="' + $column.key + '[max]]" value="' + value.max + '">';
                break
            case 'dropdown':
                let $key = $column.key,
                    $options = resp.filterOptions[$key];

                $columnFilterHtml = '<select data-filter-column="' + $column.key + '" name="' + $column.key + '">'
                    $columnFilterHtml += '<option value=""></option>'
                for(let $n in $options) {
                    let $opt = $options[$n]
                    $columnFilterHtml += '<option value="' + $opt.label + '"' +  + (($tableData.search.hasOwnProperty($column.key) && $tableData.search[$column.key] == $opt.label || $opt.selected == true) ? ' selected' : '') + '>' + $opt.label + '</option>'
                }
                $columnFilterHtml += '</select>';
                break
            case 'boolean':
                $columnFilterHtml = '<select data-filter-column="' + $column.key + '" name="' + $column.key + '">'
                $columnFilterHtml += '<option value=""></option>'
                $columnFilterHtml += '<option value="1"' + (($resp.request.search.hasOwnProperty($column.key) && $resp.request.search[$column.key] == 1) ? 'selected' : '') + '>Ja</option>'
                $columnFilterHtml += '<option value="0"' + (($resp.request.search.hasOwnProperty($column.key) && $resp.request.search[$column.key] == 0) ? 'selected' : '') + '>Nee</option>'
                $columnFilterHtml += '</select>';
                break
        }

        $filterHtml += '<td>' + $columnFilterHtml + '</td>';
        // console.log($column)
    }

    $($filterHtml).appendTo('#' + $table.attr('id') + ' thead')
    $($filterHtml).prependTo('#' + $table.attr('id') + ' tfoot')

    $('[data-filter-column]', $table).off('change keyup blur').on('change keyup blur', function(e) {
        if (window.filterChangeTimeout) { clearTimeout(window.filterChangeTimeout) }
        window.filterChangeTimeout = setTimeout(function($table, $element) {
            let $data = $table.data('load-data'),
                $position = $element.parent().parent()
            $data.search = {}
            // console.log($position)

            $('[data-filter-column]', $position).each(function() {
                let $key = $(this).attr('name'),
                    $val = $(this).val()
                $data.search[$key] = $val;
            })

            $table.data('load-data', $data)

            loadFilterTable($table, $data);

        }, 300, $table, $(this))
    })
}

window.filterChangeTimeout = null;
